import { NgClass, NgIf, NgStyle } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TooltipModule } from '@sb/tooltip';
import { Subject, takeUntil } from 'rxjs';

import { IconComponent } from '../+authenticated/shared/icon.component';
import { ControlMessages } from './control-messages.component';

@Component({
  selector: 'modal-field',
  templateUrl: 'modal-field.component.html',
  standalone: true,
  imports: [NgStyle, NgIf, IconComponent, TooltipModule, ControlMessages, NgClass],
})
export class ModalFieldComponent implements AfterViewInit, OnDestroy {
  public labelFor: string;

  @ViewChild(ControlMessages)
  public controlMessages: ControlMessages;

  @Input()
  public control: UntypedFormControl;
  @Input()
  public label: string;
  @Input()
  public errorWhen = ['dirty'];
  @Input()
  public parentCheck: string[];
  @Input()
  public tooltipText: string;
  @Input()
  public overrideError: boolean;
  @Input()
  public overrideErrorMessage: string;
  @Input()
  public showLabel = true;
  @Input()
  public textAlign = 'right';
  @Input()
  public labelPosition: string;
  @Input()
  public required: boolean;

  @Input()
  public overrideContentClass: string;

  @ViewChild('input', { static: true })
  public inputGroupEl: ElementRef;

  private destroyed$ = new Subject<void>();

  private get inputEl() {
    return this.inputGroupEl.nativeElement.querySelector('input, select, textarea, time-input, button');
  }

  public ngAfterViewInit(): void {
    if (this.inputEl?.id) {
      this.labelFor = this.inputEl.id;
    }

    void this.controlMessages?.showError$.pipe(takeUntil(this.destroyed$)).subscribe((showError) => {
      if (this.inputEl) {
        this.inputEl.setAttribute('aria-invalid', showError ? 'true' : null);
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
