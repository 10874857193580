<div class="form-modal__element">
  <label
    *ngIf="showLabel"
    [for]="labelFor"
    class="form-modal__label"
    [ngClass]="labelPosition"
    [ngStyle]="{ 'text-align': textAlign }"
    >{{ label }}
    <ng-container *ngIf="required"><span class="ml-1 text-red">*</span></ng-container>
    <icon *ngIf="!!tooltipText" class="si ml-1" svgIcon="info" [sbTooltip]="tooltipText"></icon>
  </label>
  <div
    [ngClass]="{
      'form-modal__group': !overrideContentClass,
      'has-danger': this.controlMessages?.showError || this.overrideError
    }"
    [class]="overrideContentClass"
    class="items-center"
    #input
  >
    <ng-content select="[prefix]"></ng-content>
    <ng-content></ng-content>
    <ng-content select="[suffix]"></ng-content>
  </div>
  <div *ngIf="showLabel" class="form-modal__label"></div>
  <div class="form-modal__group">
    <div class="form__validation-message form-control-feedback" *ngIf="overrideError">
      {{ overrideErrorMessage }}
    </div>
    <control-messages
      *ngIf="control"
      [control]="control"
      [when]="errorWhen"
      [parentCheck]="parentCheck"
    ></control-messages>
  </div>
</div>
